import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    breakpoints: {
        values: {
            tablet: 640,
            laptop: 1024,
            desktop: 1280
        }
    },
    palette: {
        primary: {
            main: "#0D3C79",
            form: "#3C7FFC"
        },
        text: {
            "primary": "#000000"
        },
        secondary: {
            main: "#ffffff"
        },
        contrastThreshold: 3,
        background: {
            default: "#FAFAFA"
        }
    },
    typography: {
        fontFamily: [
            'Roboto',
            'sans-serif'
        ].join(','),
        fontSize: 14,
        body1: {
          fontSize: 14
        },
        h1: {
          fontSize: 30,
          paddingBottom: "15px"
        },
        h2: {
          fontSize: 25,
          paddingBottom: "10px"
        },
        h3:{
          fontSize: 22,
          paddingBottom: "10px"
        }
    },
    components: {
        MuiTextField: {
            defaultProps: {
            variant: 'standard',
            },
        },
        MuiInputLabel: {
          defaultProps: {
            sx: {
              fontSize: "12px",
            },
          },
        },
        MuiOutlinedInput: {
          defaultProps: {
            sx: {
              fontSize: "12px",
            }
          }
        }
      },
  });

export default theme;