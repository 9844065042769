exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ehdot-js": () => import("./../../../src/pages/ehdot.js" /* webpackChunkName: "component---src-pages-ehdot-js" */),
  "component---src-pages-haku-js": () => import("./../../../src/pages/haku.js" /* webpackChunkName: "component---src-pages-haku-js" */),
  "component---src-pages-hinnasto-js": () => import("./../../../src/pages/hinnasto.js" /* webpackChunkName: "component---src-pages-hinnasto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kirjaudu-js": () => import("./../../../src/pages/kirjaudu.js" /* webpackChunkName: "component---src-pages-kirjaudu-js" */),
  "component---src-pages-payment-notify-js": () => import("./../../../src/pages/payment-notify.js" /* webpackChunkName: "component---src-pages-payment-notify-js" */),
  "component---src-pages-payment-return-js": () => import("./../../../src/pages/payment-return.js" /* webpackChunkName: "component---src-pages-payment-return-js" */),
  "component---src-pages-rekisteriseloste-js": () => import("./../../../src/pages/rekisteriseloste.js" /* webpackChunkName: "component---src-pages-rekisteriseloste-js" */),
  "component---src-pages-rekisteroidy-js": () => import("./../../../src/pages/rekisteroidy.js" /* webpackChunkName: "component---src-pages-rekisteroidy-js" */),
  "component---src-pages-tila-[id]-js": () => import("./../../../src/pages/tila/[id].js" /* webpackChunkName: "component---src-pages-tila-[id]-js" */),
  "component---src-pages-tilaesittelyt-js": () => import("./../../../src/pages/tilaesittelyt.js" /* webpackChunkName: "component---src-pages-tilaesittelyt-js" */),
  "component---src-pages-tili-js": () => import("./../../../src/pages/tili.js" /* webpackChunkName: "component---src-pages-tili-js" */),
  "component---src-pages-unohtunut-salasana-js": () => import("./../../../src/pages/unohtunut-salasana.js" /* webpackChunkName: "component---src-pages-unohtunut-salasana-js" */)
}

