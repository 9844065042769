export const types = {
    CHANGE_LOCALE: "CHANGE_LOCALE",
    MENU_OPEN: "MENU_OPEN",
    CURRENT_FIELD: "CURRENT_FIELD",
    CURRENT_GROUP: "CURRET_GROUP",
    LOADING: "LOADING",
    SCHEDULER_DATA: "SCHEDULER_DATA",
    CURRENT_VIEW: "CURRENT_VIEW",
    CURRENT_DATE: "CURRENT_DATE",
    IS_AUTHENTICATED: "IS_AUTHENTICATED"
  }