import React, { createContext } from "react";
import { Provider, useDispatch } from "react-redux";
import ThemeTopLayout from "gatsby-theme-material-ui-top-layout/src/components/top-layout";
import createStore from "../../state/createStore";
import { 
    isAuthenticatedFunction,
} from "../../actions/redux-functions"
import { Auth } from 'aws-amplify';

const AccountContext = createContext("");

export const useAccount = () => {
    const context = React.useContext(AccountContext);
    if (!context) {
      throw new Error("useAccount must be used within an AccountProvider");
    }
    return context;
  };

const Account = ({children, isAuthenticated}) => {
    const dispatch = useDispatch();
    const getSession = async () => {
        const user = await Auth.currentAuthenticatedUser()
        //console.log(user)
        return user
    }

    const authenticate = async (Username, Password) => {
        try {
            await Auth.signIn(Username, Password)
            localStorage.setItem("isLoggedIn", true)
            dispatch(isAuthenticatedFunction(true))
            return {ok: true}
        } catch (error) {
            console.log('error signing in', error)
            return {ok: false, error: error}
        }
    }

    const logout = async () => {
        try {
            await Auth.signOut()
            localStorage.setItem("isLoggedIn", false)
            dispatch(isAuthenticatedFunction(false))
        } catch (error) {
            console.log('error signing out: ', error)
        }
    };

    return (
        <AccountContext.Provider
        value={{
            authenticate,
            getSession,
            logout,
            dispatch,
            isAuthenticated
        }}
        >
        {children}
        </AccountContext.Provider>
    );
};

export default function TopLayout({ children, theme }) {
  const store = createStore();
  //console.log("TOPLAYOUT");
  return (
    <Provider store={store}>
        <Account
    >
      <ThemeTopLayout theme={theme}>{children}</ThemeTopLayout>
      </Account>
    </Provider>
  );
}