import { configureStore } from "@reduxjs/toolkit";
import { types } from "../actions/types";

export const vakiovuoroStart = new Date('2024-08-05')
export const vakiovuoroEnd = new Date('2025-05-25 23:00:00')

export const vakiovuoroStartSummer = new Date('2024-05-01')
export const vakiovuoroEndSummer = new Date('2024-10-31 23:00:00')

const date = new Date() // vakiovuoroStart 

const initialState = {
    locale: 'fi-FI',
    isMenuOpen: true,
    currentField: {
      id:'liikuntahalli', 
      text: 'Koskisen areena',
      children: [
        {id: 1},
        {id: 2},
        {id: 3},
      ]},
    currentGroup: null,
    loading: false,
    schedulerData: [],
    currentView: 'Week',
    currentDate: date,
    isAuthenticated: false,
  };

const appState = (state = initialState, action) => {
    switch (action.type) {
      case types.CHANGE_LOCALE:
        return { ...state, locale: action.localeStatus };
      case types.MENU_OPEN:
          return { ...state, isMenuOpen: action.isMenuOpen };
      case types.CURRENT_FIELD:
          return { ...state, currentField: action.currentField };
      case types.CURRENT_GROUP:
          return { ...state, currentGroup: action.currentGroup };
      case types.LOADING:
          return { ...state, loading: action.loading };
      case types.SCHEDULER_DATA:
          return { ...state, schedulerData: action.schedulerData };
      case types.CURRENT_VIEW:
          return { ...state, currentView: action.currentView };
      case types.CURRENT_DATE:
          return { ...state, currentDate: action.currentDate };
      case types.IS_AUTHENTICATED:
          return { ...state, isAuthenticated: action.isAuthenticated };
      default:
        return state;
    }
  }


const createStore = () => configureStore(
    { 
        reducer: appState, preloadedState: initialState 
    });

export default createStore;