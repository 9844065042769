import { types } from "./types";

export const changeLocaleFunction = (data) => (dispatch) => {
      dispatch({
        type: types.CHANGE_LOCALE,
        localeStatus: data,
      });
};

export const isMenuOpenFunction = (data) => (dispatch) => {
    dispatch({
      type: types.MENU_OPEN,
      isMenuOpen: data,
    });
    //console.log('menu open:' + data)
};

export const isAuthenticatedFunction = (data) => (dispatch) => {
  dispatch({
    type: types.IS_AUTHENTICATED,
    isAuthenticated: data,
  });
  //console.log('menu open:' + data)
};

export const currentFieldFunction = (data) => (dispatch) => {
    dispatch({
      type: types.CURRENT_FIELD,
      currentField: data,
    });
};

export const currentGroupFunction = (data) => (dispatch) => {
    dispatch({
      type: types.CURRENT_GROUP,
      currentGroup: data,
    });
};

export const loadingFunction = (data) => (dispatch) => {
  dispatch({
    type: types.LOADING,
    loading: data,
  });
};

export const schedulerDataFunction = (data) => (dispatch) => {
  dispatch({
    type: types.SCHEDULER_DATA,
    schedulerData: data,
  });
};

export const currentViewFunction = (data) => (dispatch) => {
  dispatch({
    type: types.CURRENT_VIEW,
    currentView: data,
  });
};

export const currentDateFunction = (data) => (dispatch) => {
  dispatch({
    type: types.CURRENT_DATE,
    currentDate: data,
  });
};